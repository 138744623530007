<template>
  <span :title="title">{{ formattedDuration }}</span>
</template>

<script>
import moment from 'moment' // Time library, see https://momentjs.com/docs/
import momentDurationFormatSetup from 'moment-duration-format' // Time library duration formatting plugin
momentDurationFormatSetup(moment) // Add plugin to library

export default {
  name: 'DurationItem',
  props: {
    duration: Number, // In seconds
    format: {
      type: String,
      default: '*h:mm' // For DURATION format strings, see https://momentjs.com/docs/#/plugins/duration-format/
    }
  },
  computed: {
    formattedDuration() {
      return moment.duration(this.duration, 'seconds').format(this.format)
    },
    title() {
      return this.duration > 0 ? moment.duration(this.duration, 'seconds').humanize() : null
    }
  },
  created() {
    moment.locale(this.$i18n?.global?.locale?.value?.substring(0, 2) ?? 'en')
  }
}
</script>
